// Gatsby supports TypeScript natively!
import React, { Component } from "react"
import { PageProps, Link } from "gatsby"
import csvGenerate  from 'json-to-csv-export';
import papa from 'papaparse';
import Layout from "../components/layout"
import SEO from "../components/seo"
import './appjson.css'

const schema_first = {
  title: 'Example Schema',
  type: 'object',
  properties: {
    array: {
      type: 'array',
      items: {
        type: 'number'
      }
    },
    boolean: {
      type: 'boolean'
    },
    number: {
      type: 'number'
    }
  },
  required: ['array', 'string', 'boolean']
};

const json = [{
  'array': [1, 2, 3],
  'boolean': true,
  'null': null,
  'number': 'four',
  'object': { 'a': 'b', 'c': 'd' },
  'string': 'Hello World'
}];

const modes = ['tree', 'form', 'view', 'code', 'text'];


const JSONEditorReact = React.lazy(() =>
  import("./../lib/JSONEditorReact")
)

class JsonEditor extends Component {

  state = {
    schema_first,
    text_first: JSON.stringify({}, null, 2),
    // text_first: JSON.stringify(json, null, 2),
    mode_first: 'code',
    text_sec: JSON.stringify({}, null, 2),
    mode_sec: 'tree'
  };


  onChangeText = (text_first) => {
    if (text_first) {
      this.setState({ text_first });
    }

  };


  handleRight = () => {
    if (this.state.text_first != undefined) {
      this.setState({ "text_sec": this.state.text_first });
    }

  }

  handleLeft = () => {
    if (this.state.text_sec != undefined) {
      this.setState({ "text_first": this.state.text_sec });
    }

  }

  onChangeText2 = (text_sec) => {
    this.setState({ text_sec });
  };

  onModeChangeSelect = (event) => {
    this.setState({ mode: event.target.value });
  };

  onModeChange = (mode_first) => {
    this.setState({ mode_first });
  };


  onModeChange2 = (mode_sec) => {
    this.setState({ mode_sec });
  };


  render() {
    const isSSR = typeof window === "undefined"
 console.log(   papa.unparse(json));

    return (

      <>
       <Layout>
      <SEO title="Json Editor, Json Viewer and JSON Beatify Online Tool" description={"JSON editor is an online tool to view edit and beautify JSON ."} />
      {!isSSR && (
        <React.Suspense fallback={<div />}>
        
        <div className="dd">


          <div className="app">
            <div className="main-content-json">
              <h2 style={{ textAlign: 'center' }}>JSON Editor , JSON Viewer and JSON Beautify</h2>

              <div className="row">
                <div className="col-md-5" style={{ height: '600px' }}>
                  <JSONEditorReact
                    text={this.state.text_first}
                    mode={this.state.mode_first}
                    modes={modes}
                    indentation={4}
                    onChangeText={this.onChangeText}
                    onModeChange={this.onModeChange}
                  />
                </div>
                <div className="col-md-2" style={{textAlign:'center'}}>
                  <p>Copy Content</p>
                  <button type="button" className="btn btn-outline-primary" onClick={this.handleLeft} ><i className="bi bi-arrow-bar-left"></i></button>
                  {' '}
                  <button type="button" className="btn btn-outline-primary" onClick={this.handleRight}>
                    <i className="bi bi-arrow-bar-right"></i>

                  </button>
                </div>
                <div className="col-md-5">
                  

                <div className="card mb-3">
 

</div>
<div className="card">
  <div className="card-body">
    <h4 className="card-title">Converted TO CSV</h4>
    <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6>
    <p className="card-text">
      <pre>
        
      </pre>
    </p>
    <a href="#" className="card-link">Card link</a>
    <a href="#" className="card-link">Another link</a>
  </div>
</div>

                </div>
              </div>
            </div>

          </div>
        </div>

     
        </React.Suspense>
        
      )}
       </Layout>
    </>

      
    )

  }


}

export default JsonEditor;
